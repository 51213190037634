import React, { useEffect } from 'react';
import Button from '../../atoms/button/Button';
import { Link, useNavigate } from 'react-router-dom';
import paths from '../../../constants/paths';
import { getCookie } from '../../../utils/helper/helper';

const NotFound = () => {
  const navigate = useNavigate();
  const token = getCookie('token');

  useEffect(() => {
    if (!token) {
      navigate(paths.login);
    }
  }, [navigate, token]);

  return (
    <div className='h-screen background flex justify-center items-center'>
      <div className='sidemenu p-10 rounded-lg shadow-lg'>
        <h1 className='text-4xl font-bold mb-4'>Page Not Found</h1>
        <p className='text-color'>
          Oops! The page you are looking for does not exist.
        </p>
        <Link to='/'>
          <Button
            title='Go back'
            color='btn-primary'
            className='mt-4'
          />
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
