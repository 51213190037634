import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  campaignCategories: [],
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getCampaignCategory = createAsyncThunk('campaign/getCampaignCategory', async (params) => {
  const response = await handleAPI('/platform/campaign_categories', 'get', params);
  return response;
});

const campaignCategorySlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    editCampaignCategory: (state, action) => {
      const campaignCategories = [...state.campaignCategories];
      const updatedRecord = action.payload;
      const index = campaignCategories.findIndex((vertical) => vertical.id === updatedRecord.id);
      if (index !== -1) {
        campaignCategories[index] = updatedRecord;
      }
      state.campaignCategories = campaignCategories;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCampaignCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCampaignCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.campaignCategories = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getCampaignCategory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});
export const { editCampaignCategory } = campaignCategorySlice.actions;

export default campaignCategorySlice.reducer;
