import { deserialize } from 'deserialize-json-api';

const serializeMiddleware = (store) => (next) => (action) => {
  if (typeof action === 'function') {
    return action(store.dispatch, store.getState);
  }

  if (action.payload) {
    delete action.payload.headers;
    delete action.payload.config;
    delete action.payload.request;
  }

  if (action?.payload?.data?.data) {
    const deserializedData = deserialize(action.payload.data);
    const newAction = {
      ...action,
      payload: deserializedData.data,
      metaData: action.payload.data.meta,
    };
    return next(newAction);
  } else {
    return next(action);
  }
};
export default serializeMiddleware;
