import React from 'react';
import Button from '../../atoms/button/Button';
import { Link } from 'react-router-dom';

const ErrorBoundary = () => {
  return (
    <div className='h-screen background flex justify-center items-center'>
      <div className='sidemenu p-10 rounded-lg shadow-lg'>
        <h1 className='text-4xl font-bold mb-4'>Oops, something went wrong!</h1>
        <p className='text-color'>We apologize for the inconvenience.</p>
        <Link to='/'>
          <Button
            title='Go back'
            color='btn-primary'
            className='mt-4'
          />
        </Link>
      </div>
    </div>
  );
};

export default ErrorBoundary;
