import React from 'react';

const TableCell = ({ classname, children, isEmpty, ...props }) => {
  return (
    <td
      className={classname}
      {...props}>
      {children || isEmpty ? children : '---'}
    </td>
  );
};

export default TableCell;
