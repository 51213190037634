import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../tooltip/tooltip';

const Button = ({
  title,
  color,
  icon,
  size = 'btn-sm',
  className,
  onClickHandler,
  textColor = 'text-white',
  disable = false,
  isCircle = false,
  isIconFilled = true,
  isTableButton = false,
  textSize = '2xl:text-[16px] text-[20px]',
  isOutlined = false,
  infoData,
  type,
}) => {
  let buttonClasses = `cursor-pointer btn gap-2 capitalize ${disable && 'cursor-not-allowed'} ${textColor} ${
    isOutlined ? 'btn-outline hover:!bg-primary' : 'border-0'
  } ${size} ${color} ${className}`;
  if (isCircle) {
    buttonClasses += ` w-[32px] btn-circle`;
  }
  if (isTableButton) {
    buttonClasses += ` btn-circle bg-opacity-10 hover:bg-opacity-10`;
  }
  let iconClasses = `material-symbols-outlined ${isIconFilled ? 'filled-icon' : ''} ${textSize}`;

  return (
    <>
      <button
        className={buttonClasses}
        onClick={() => onClickHandler?.()}
        type={type}
        disabled={disable}>
        {icon && <span className={iconClasses}>{icon}</span>}
        {title}
      </button>
      {infoData && (
        <Tooltip
          infoData={infoData}
          className='ml-2'
        />
      )}
    </>
  );
};

Button.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  disable: PropTypes.any,
  isCircle: PropTypes.bool,
  isTransition: PropTypes.bool,
  onClickHandler: PropTypes.func,
  isIconFilled: PropTypes.bool,
  textSize: PropTypes.string,
};

export default Button;
