import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../utils/api/api';
import { removeCookie, setAuthCookie } from '../../utils/helper/helper';

const initialState = {
  auth: {},
  abilities: localStorage.getItem('abilities') !== 'undefined' ? JSON.parse(localStorage.getItem('abilities')) : {},
  role: localStorage.getItem('role') ? localStorage.getItem('role') : {},
  authenticate: false,
  error: null,
  rememberMe: false
};

var rememberMe = false;

export const authUser = createAsyncThunk('auth/Login', async (data) => {
  const response = await handleAPI('platform/auth/login', 'post', '', data);
  rememberMe = data.rememberMe;
  return response;
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    Logout: (state) => {
      state.authenticate = false;
      removeCookie('token');
      removeCookie('email');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authUser.fulfilled, (state, action) => {
      state.auth = action.payload;
      state.role = action.payload.role;
      if (action.payload.role) {
        localStorage.setItem('role', action.payload.role);
      }
      if (action.payload.abilities) {
        localStorage.setItem('abilities', JSON.stringify(action.payload.abilities));
      }
      state.abilities = action.payload.abilities;
      state.authenticate = true;
      setAuthCookie('token', action.metaData.token, rememberMe);
      setAuthCookie('email', action.payload.email, rememberMe);
      setAuthCookie('timezone', action.payload.timezone ? action.payload.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone, rememberMe);
    });
  },
});

export const { Logout } = authSlice.actions;

export default authSlice.reducer;
