import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  affiliateScrubs: [],
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getAffiliateScrubs = createAsyncThunk('affiliateScrubs/getAffiliateScrubs', async ({ id, params }) => {
  const response = await handleAPI(`platform/affiliates/${id}/scrub_settings`, 'get', params);
  return response;
});

const affiliateScrubSlice = createSlice({
  name: 'affiliateScrub',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAffiliateScrubs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAffiliateScrubs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.affiliateScrubs = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getAffiliateScrubs.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default affiliateScrubSlice.reducer;
