import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  orders: [],
  order: {},
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getOrders = createAsyncThunk('orders/getOrders', async (params) => {
  const response = await handleAPI('/platform/orders', 'get', params);
  return response;
});

export const getOrder = createAsyncThunk('orders/getOrder', async ({ number, params }) => {
  const response = await handleAPI(`/platform/orders/${number}`, 'get', params);
  return response;
});

const orderSlice = createSlice({
  name: 'order',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orders = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getOrders.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.order = action.payload;
    });
  },
});

export default orderSlice.reducer;
