import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import paths from '../paths';
import Loader from '../../components/atoms/loader/Loader';
const PaymentLayout = lazy(() => import('../../components/layout/payment/PaymentLayout'));
const Gateways = lazy(() => import('../../components/pages/payment/gateways/Gateways'));
const AddGateway = lazy(() => import('../../components/pages/payment/gateways/AddGateway'));
const Corporations = lazy(() => import('../../components/pages/payment/corporations/Corporations'));
const Banks = lazy(() => import('../../components/pages/payment/banks/Banks'));
const Pools = lazy(() => import('../../components/pages/payment/pools/Pools'));
const RebillPools = lazy(() => import('../../components/pages/payment/rebillPools/RebillPools'));
const AddRebillPools = lazy(() => import('../../components/pages/payment/rebillPools/AddRebillPools'));
const AddPool = lazy(() => import('../../components/pages/payment/pools/AddPool'));
const BinRoutings = lazy(() => import('../../components/pages/payment/binRouting/BinRouting'));
const AddBinRouting = lazy(() => import('../../components/pages/payment/binRouting/AddBinRouting'));
const GatewayProfiles = lazy(() => import('../../components/pages/payment/gatewayProfiles/GatewayProfiles'));
const AddGatewayProfile = lazy(() => import('../../components/pages/payment/gatewayProfiles/AddGatewayProfile'));
const DefaultMapping = lazy(() => import('../../components/pages/payment/settings/DefaultMapping'));
const ReprocFlowMapping = lazy(() => import('../../components/pages/payment/settings/ReprocFlowMapping'));
const RebillFlowMapping = lazy(() => import('../../components/pages/payment/settings/RebillFlowMapping'));

const createLazyComponent = (Component) => (
  <Suspense fallback={<Loader spinner />}>
    <Component />
  </Suspense>
);

export const paymentRoutes = (abilities) => {
  const createRoute = (path, Component) => ({
    path,
    element: createLazyComponent(Component),
  });

  return {
    path: paths.getPaymentLayout,
    element: createLazyComponent(PaymentLayout),
    children: [
      {
        index: true,
        element: (
          <Navigate
            replace={true}
            to={paths.gateways}
          />
        ),
      },
      (abilities?.gateway?.read ?? false) && createRoute(paths.gateways, Gateways),
      (abilities?.gateway?.write ?? false) && createRoute(paths.newGateway, AddGateway),
      (abilities?.gateway?.write ?? false) && createRoute(paths.editGateway(), AddGateway),
      (abilities?.corporation?.read ?? false) && createRoute(paths.corporations, Corporations),
      (abilities?.bank?.read ?? false) && createRoute(paths.banks, Banks),
      (abilities?.pool?.read ?? false) && createRoute(paths.pools, Pools),
      (abilities?.pool?.write ?? false) && createRoute(paths.newPool, AddPool),
      (abilities?.pool?.write ?? false) && createRoute(paths.editPool(), AddPool),

      (abilities?.rebill_pool?.read ?? false) && createRoute(paths.rebillPools, RebillPools),
      (abilities?.rebill_pool?.write ?? false) && createRoute(paths.editRebillPool(), AddRebillPools),
      (abilities?.rebill_pool?.write ?? false) && createRoute(paths.newRebillPool, AddRebillPools),
      (abilities?.bin_routing?.read ?? false) && createRoute(paths.binRoutings, BinRoutings),
      (abilities?.bin_routing?.write ?? false) && createRoute(paths.newBinRoutings, AddBinRouting),
      (abilities?.bin_routing?.read ?? false) && createRoute(paths.editBinRoutings(), AddBinRouting),
      (abilities?.gateway_profile?.read ?? false) && createRoute(paths.gatewayProfiles, GatewayProfiles),
      (abilities?.gateway_profile?.write ?? false) && createRoute(paths.newGatewayProfile, AddGatewayProfile),
      (abilities?.gateway_profile?.write ?? false) && createRoute(paths.editGatewayProfile(), AddGatewayProfile),
      (abilities?.settings?.read ?? false) && createRoute(paths.defaultMapping, DefaultMapping),
      (abilities?.settings?.read ?? false) && createRoute(paths.reprocflowMapping, ReprocFlowMapping),
      (abilities?.settings?.read ?? false) && createRoute(paths.rebillflowMapping, RebillFlowMapping),
      {
        path: paths.settings,
        element: <Navigate to={paths.defaultMapping} />,
      },
    ],
  };
};
