import { createSlice } from '@reduxjs/toolkit';
import { getCookie, setCookie } from '../../utils/helper/helper';

const initialState = {
  theme: getCookie('theme') ?? 'light',
  isLoading: true,
  error: null,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    ChangeTheme: (state, action) => {
      state.theme = action.payload;
      setCookie('theme', action.payload);
    },
  },
});
export const { ChangeTheme } = themeSlice.actions;

export default themeSlice.reducer;
