import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  repocMapppers: [],
  isLoading: true,
  error: null,
  totalPage: null,
  totalCount: null,
};

export const getRepocMappers = createAsyncThunk('repoc/getRepocMappers', async (params) => {
  const response = await handleAPI('platform/reproc_gateway_mappers', 'get', params);
  return response;
});

const repocMapperSlice = createSlice({
  name: 'repoc',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getRepocMappers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRepocMappers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.repocMapppers = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
    });
    builder.addCase(getRepocMappers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default repocMapperSlice.reducer;
