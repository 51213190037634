import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  campaigns: [],
  campaign: {},
  offers: [],
  offer: {},
  upsells: [],
  upsell: {},
  affiliates: [],
  affiliate: {},
  autoResponders: [],
  pixels: [],
  autoResponder: {},
  pixel: {},
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getCampaigns = createAsyncThunk('campaigns/getCampaigns', async (params) => {
  const response = await handleAPI('/platform/campaigns', 'get', params);
  return response;
});

export const getOffers = createAsyncThunk('campaigns/getOffers', async ({ id, params }) => {
  const response = await handleAPI(`/platform/campaigns/${id}/products`, 'get', params);
  return response;
});

export const getOffer = createAsyncThunk('campaigns/getOffer', async ({ id, offerId, params }) => {
  const response = await handleAPI(`/platform/campaigns/${id}/products/${offerId}`, 'get', params);
  return response;
});

export const getUpsell = createAsyncThunk('campaigns/getUpsell', async ({ id, upsellId, params }) => {
  const response = await handleAPI(`/platform/campaigns/${id}/products/${upsellId}`, 'get', params);
  return response;
});

export const getUpsells = createAsyncThunk('campaigns/getUpsells', async ({ id, params }) => {
  const response = await handleAPI(`/platform/campaigns/${id}/products`, 'get', params);
  return response;
});

export const getCampaign = createAsyncThunk('campaigns/getCampaign', async ({ id, params }) => {
  const response = await handleAPI(`/platform/campaigns/${id}`, 'get', params);
  return response;
});

export const getAffiliates = createAsyncThunk('campaigns/getAffiliates', async ({ id, params }) => {
  const response = await handleAPI(`/platform/campaigns/${id}/affiliates`, 'get', params);
  return response;
});

export const getAffiliate = createAsyncThunk('campaigns/getAffiliate', async ({ id, affiliateId, params }) => {
  const response = await handleAPI(`/platform/campaigns/${id}/affiliates/${affiliateId}`, 'get', params);
  return response;
});

export const getAutoResponders = createAsyncThunk('campaigns/getAutoResponders', async ({ id, params }) => {
  const response = await handleAPI(`platform/campaigns/${id}/auto_responders`, 'get', params);
  return response;
});

export const getAutoResponder = createAsyncThunk('campaigns/getAutoResponder', async ({ id, emailId, params }) => {
  const response = await handleAPI(`/platform/campaigns/${id}/auto_responders/${emailId}`, 'get', params);
  return response;
});

export const getCampaignPixels = createAsyncThunk('campaigns/getCampaignPixels', async ({ id, params }) => {
  const response = await handleAPI(`/platform/campaigns/${id}/pixel_settings`, 'get', params);
  return response;
});

export const getCampaignPixel = createAsyncThunk('campaigns/getCampaignPixel', async ({ id, pixelId, params }) => {
  const response = await handleAPI(`/platform/campaigns/${id}/pixel_settings/${pixelId}`, 'get', params);
  return response;
});

const campaignSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    deleteCampaign: (state, action) => {
      state.campaigns = state.campaigns.filter((campaign) => campaign.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCampaigns.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCampaigns.fulfilled, (state, action) => {
      state.isLoading = false;
      state.campaigns = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getOffers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOffers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.offers = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getUpsells.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUpsells.fulfilled, (state, action) => {
      state.isLoading = false;
      state.upsells = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getCampaign.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCampaign.fulfilled, (state, action) => {
      state.isLoading = false;
      state.campaign = action.payload;
    });
    builder.addCase(getOffer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOffer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.offer = action.payload;
    });
    builder.addCase(getUpsell.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUpsell.fulfilled, (state, action) => {
      state.isLoading = false;
      state.upsell = action.payload;
    });
    builder.addCase(getAffiliates.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAffiliates.fulfilled, (state, action) => {
      state.isLoading = false;
      state.affiliates = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getAffiliate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.affiliate = action.payload;
    });
    builder.addCase(getAutoResponders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAutoResponders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.autoResponders = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getAutoResponder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.autoResponder = action.payload;
    });
    builder.addCase(getCampaignPixels.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCampaignPixels.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pixels = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getCampaignPixel.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pixel = action.payload;
    });
  },
});
export const { deleteCampaign } = campaignSlice.actions;

export default campaignSlice.reducer;
