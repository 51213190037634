import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  subscriptions: [],
  subscription: {},
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getSubscriptions = createAsyncThunk('subscriptions/getSubscriptions', async (params) => {
  const response = await handleAPI('platform/subscriptions', 'get', params);
  return response;
});

export const getSubscription = createAsyncThunk('subscriptions/getSubscription', async ({ number, params }) => {
  const response = await handleAPI(`platform/subscriptions/${number}`, 'get', params);
  return response;
});

const subscriptionSlice = createSlice({
  name: 'clicks',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSubscriptions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubscriptions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subscriptions = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getSubscriptions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getSubscription.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubscription.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subscription = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
  },
});

export default subscriptionSlice.reducer;
