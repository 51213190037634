import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideToastr } from '../../../redux/slices/toastrSlice';

const Toastr = () => {
  const dispatch = useDispatch();
  const toastrList = useSelector((state) => state.toastr.toastrList);
  const [hoveredToastr, setHoveredToastr] = useState(undefined);

  useEffect(() => {
    toastrList.forEach((toastr, index) => {
      const timer = setTimeout(() => {
        if (index !== hoveredToastr) {
          dispatch(hideToastr(index));
        }
      }, 3500 * (index + 1));
      return () => clearTimeout(timer);
    });
  }, [toastrList, dispatch, hoveredToastr]);

  const setBgColor = (type) => {
    switch (type) {
      case 'success':
        return 'bg-green';
      case 'error':
        return 'bg-danger';
      default:
        return 'bg-green';
    }
  };

  const icon = {
    success: 'check_circle',
    error: 'error',
  };

  const handleMouseEnter = (index) => {
    setHoveredToastr(index);
  };

  const handleMouseLeave = () => {
    setHoveredToastr(null);
  };

  return (
    <div className='fixed bottom-4 right-4'>
      {toastrList.map((toastr, index) => (
        <div
          key={index}
          className={`flex max-w-[600px] justify-between gap-10 my-2 p-2 rounded-lg shadow-lg text-white ${setBgColor(toastr.type)}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}>
          <div className='flex items-center'>
            <span className='material-symbols-outlined'>{icon[toastr.type]}</span>
            <p className='mb-0 text-[15px] ml-2'>{toastr.message}</p>
          </div>
          <button
            className='ml-2 h-2'
            onClick={() => dispatch(hideToastr(index))}>
            <span className='material-symbols-outlined'>close</span>
          </button>
        </div>
      ))}
    </div>
  );
};

export default Toastr;
