import React from 'react';
import { NavLink } from 'react-router-dom';

const SidebarItem = ({ path, name }) => {
  return (
    <div>
      <NavLink
        to={path}
        className={({ isActive }) => `${isActive && 'text-white bg-primary'} block p-2 my-2 rounded text-small`}>
        {name}
      </NavLink>
    </div>
  );
};

export default SidebarItem;
