import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toastrList: [],
};

const toastrSlice = createSlice({
  name: 'toastr',
  initialState,
  reducers: {
    showToastr: (state, action) => {
      state.toastrList.push(action.payload);
    },
    hideToastr: (state) => {
      state.toastrList.shift();
    },
  },
});

export const { showToastr, hideToastr } = toastrSlice.actions;

export default toastrSlice.reducer;
