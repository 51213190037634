const sidebarPaths = {
  getDashboardLayout: 'dashboard',
  getCustomerLayout: 'customer',
  getProductLayout: 'product',
  getCampaignLayout: 'campaign',
  getCampaignEditLayout: (id = ':id') => `campaign/${id}`,
  getOrderLayout: 'order',
  getPaymentLayout: 'payment',
  getAdminLayout: 'admin',
  getAccountLayout: 'account',
};

export default sidebarPaths;
