import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import paths from '../paths';
import Loader from '../../components/atoms/loader/Loader';
const OrderLayout = lazy(() => import('../../components/layout/order/OrderLayout'));
const Orders = lazy(() => import('../../components/pages/order/orders/Orders'));
const Fulfilments = lazy(() => import('../../components/pages/order/fulfilments/Fulfilments'));
const Clicks = lazy(() => import('../../components/pages/order/click/Clicks'));
const Subscriptions = lazy(() => import('../../components/pages/order/subscription/Subscriptions'));
const AutoReruns = lazy(() => import('../../components/pages/order/autoRerun/AutoReruns'));
const Order = lazy(() => import('../../components/pages/order/orders/orderDetails/Order'));
const Subscription = lazy(() => import('../../components/pages/order/subscription/subscriptionDetails/Subscription'));
const Transactions = lazy(() => import('../../components/pages/order/transaction/Transactions'));

const createLazyComponent = (Component) => (
  <Suspense fallback={<Loader spinner />}>
    <Component />
  </Suspense>
);

export const orderRoutes = (abilities) => {
  const createRoute = (path, Component) => ({
    path,
    element: createLazyComponent(Component),
  });
  return [
    {
      path: paths.getOrderLayout,
      element: createLazyComponent(OrderLayout),
      children: [
        {
          index: true,
          element: (
            <Navigate
              replace={true}
              to={paths.orders}
            />
          ),
        },
        (abilities?.order?.read ?? false) && createRoute(paths.orders, Orders),
        (abilities?.fulfillment?.read ?? false) && createRoute(paths.orderFulfilments, Fulfilments),
        (abilities?.click?.read ?? false) && createRoute(paths.clicks, Clicks),
        (abilities?.subscription?.read ?? false) && createRoute(paths.subscriptions, Subscriptions),
        (abilities?.auto_rerun?.read ?? false) && createRoute(paths.autoReruns, AutoReruns),
        (abilities?.transactions?.write ?? false) && createRoute(paths.transactions, Transactions),
      ],
    },
    (abilities?.order?.read ?? false) && createRoute(paths.order(), Order),
    (abilities?.subscription?.read ?? false) && createRoute(paths.subscription(), Subscription),
  ];
};
