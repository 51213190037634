import Cookies from 'js-cookie';
import AWS from 'aws-sdk';
import Jsona from 'jsona';
import moment from 'moment-timezone';
import fileDownload from 'js-file-download';
import { OUTLET_WRAPPER_ID } from 'constants/ids';

const dataFormatter = new Jsona();

export const setCookie = (name, value) => {
  Cookies.set(name, value);
};

export const setAuthCookie = (name, value, rememberMe = false) => {
  if (rememberMe) {
    // Persistent cookie with expiration of 2 months
    let expires = new Date();
    let expirationTime = 3600 * 24 * 60;
    expires.setTime(expires.getTime() + expirationTime * 1000);
    Cookies.set(name, value, { expires });
  } else {
    // Just a simple session cookie
    Cookies.set(name, value);
  }
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const removeCookie = (name) => {
  Cookies.remove(name);
};

export const deserializeData = (data) => {
  const response = dataFormatter.deserialize(data);
  return response;
};

export const checkNestedValue = (abilities, key) => {
  const levels = key.split('.');
  let current = abilities;

  for (const level of levels) {
    if (!Object.prototype.hasOwnProperty.call(current, level)) {
      return false;
    }
    current = current[level];
  }

  const checkValue = (value) => {
    if (typeof value === 'boolean' && value) {
      return true;
    } else if (typeof value === 'object') {
      return Object.values(value).some((v) => checkValue(v));
    }
    return false;
  };

  return checkValue(current);
};

export const uploadImageToS3 = async () => {
  const accessKeyId = process.env.REACT_APP_EXOSCALE_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_EXOSCALE_SECRET_ACCESS_KEY;
  const enpoint = process.env.REACT_APP_EXOSCALE_ENDPOINT;
  const region = process.env.REACT_APP_EXOSCALE_REGION;

  AWS.config.update({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: region,
    endpoint: new AWS.Endpoint(enpoint),
    s3ForcePathStyle: true,
  });

  const s3 = new AWS.S3();
  return s3;
};

export const timeFormatter = (
  time,
  format = 'DD MMM YYYY HH:mm'
) => {
  if (time) {
    return moment.parseZone(time).format(format);
  } else {
    return '---';
  }
};

export const capitalizeFirstLetter = (str) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
};

export const getStatusColor = (status) => {
  switch (status) {
  case 'partial':
  case 'partial_refunded':
  case 'pending':
  case 'soft_decline':
  case 'processing':
  case 'hold':
  case 'rma_pending':
  case 'confirm_pending':
    return 'text-primary';

  case 'complete':
  case 'completed':
  case 'success':
  case 'shipped':
  case 'delivered':
  case 'active':
  case 'refunded':
  case 'true':
    return 'text-green';

  case 'declined':
  case 'failed':
  case 'hard_decline':
  case 'canceled':
  case 'inactive':
  case 'null':
    return 'text-danger';

  default:
    return '';
  }
};

export const formatStatus = (label) => {
  return label?.replace(/_/g, ' ');
};

export const removeEmptyData = (obj) => {
  if (obj && typeof obj === 'object') {
    Object.keys(obj).forEach((key) => {
      if (
        obj[key] &&
        typeof obj[key] === 'object' &&
        !Array.isArray(obj[key])
      ) {
        removeEmptyData(obj[key]);
      } else if (
        obj[key] === '' ||
        obj[key] === null ||
        obj[key] === undefined
      ) {
        delete obj[key];
      }
    });
  }
  return obj;
};

export const fileDownloader = (data, filename = 'report.csv') => {
  fileDownload(data, filename);
};

export const csvExport = (data, page) => {
  const datestamp = moment().format('YYYYMMDDHHmmss');
  fileDownloader(data, `${page}_${datestamp}.csv`);
}

export const scrollToTop = (id = OUTLET_WRAPPER_ID) => {
  setTimeout(() => document.getElementById(id)?.scrollTo({ top: 0, behavior: 'smooth' }));
}

export const convertToUserTimezone = (date) => {
  const timezone = getCookie('timezone');
  const dateWithoutZone = moment.tz(date, timezone).format("YYYY-MM-DDTHH:mm:ss.SSS")
  const localZone = moment(dateWithoutZone).format("Z")
  const dateWithLocalZone = [dateWithoutZone, localZone].join("")
  return new Date(dateWithLocalZone)
}

export const convertToSelectedTimezone = (date) => {
  const timezone = getCookie('timezone');
  const dateWithoutZone = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS")
  const otherZone = moment.tz(date, timezone).format("Z")
  const dateWithOtherZone = [dateWithoutZone, otherZone].join("")
  return new Date(dateWithOtherZone)
}

export const transformNumberFieldValue = (value) => (isNaN(value) ? null : value);
