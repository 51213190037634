import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  rebillMapppers: [],
  isLoading: true,
  error: null,
  totalPage: null,
  totalCount: null,
};

export const getRebillMappers = createAsyncThunk('rebill/getRebillMappers', async (params) => {
  const response = await handleAPI('platform/rebill_gateway_mappers', 'get', params);
  return response;
});

const rebillMapperSlice = createSlice({
  name: 'rebill',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getRebillMappers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRebillMappers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.rebillMapppers = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
    });
    builder.addCase(getRebillMappers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default rebillMapperSlice.reducer;
