import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  verticals: [],
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getVerticals = createAsyncThunk('verticals/getVerticals', async (params) => {
  const response = await handleAPI('/platform/verticals', 'get', params);
  return response;
});

const verticalsSlice = createSlice({
  name: 'verticals',
  initialState,
  reducers: {
    editVertical: (state, action) => {
      const verticals = [...state.verticals];
      const updatedRecord = action.payload;
      const index = verticals.findIndex((vertical) => vertical.id === updatedRecord.id);
      if (index !== -1) {
        verticals[index] = updatedRecord;
      }
      state.verticals = verticals;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVerticals.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVerticals.fulfilled, (state, action) => {
      state.isLoading = false;
      state.verticals = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getVerticals.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});
export const { editVertical } = verticalsSlice.actions;

export default verticalsSlice.reducer;
