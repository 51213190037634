import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  optionTypes: [],
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getOptionTypes = createAsyncThunk('optionTypes/getOptionTypes', async (params) => {
  const response = await handleAPI('/platform/option_types', 'get', params);
  return response;
});

const optionTypeSlice = createSlice({
  name: 'optionTypes',
  initialState,
  reducers: {
    editOptionType: (state, action) => {
      const optionTypes = [...state.optionTypes];
      const updatedRecord = action.payload;
      const index = optionTypes.findIndex((optionType) => optionType.id === updatedRecord.id);
      if (index !== -1) {
        optionTypes[index] = updatedRecord;
      }
      state.optionTypes = optionTypes;
      state.isLoading = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getOptionTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOptionTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.optionTypes = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getOptionTypes.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});
export const { editOptionType } = optionTypeSlice.actions;

export default optionTypeSlice.reducer;
