import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  testCards: [],
  meta: {},
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getTestCards = createAsyncThunk('testCard/getTestCards', async (params) => {
  const response = await handleAPI('platform/test_cards', 'get', params);
  return response;
});

const testCardSlice = createSlice({
  name: 'testCard',
  initialState,
  reducers: {
    deleteTestCard: (state, action) => {
      state.testCards = state.testCards.filter((testCard) => testCard.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTestCards.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTestCards.fulfilled, (state, action) => {
      state.isLoading = false;
      state.testCards = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.meta = action.metaData;
    });
    builder.addCase(getTestCards.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});
export const { deleteTestCard } = testCardSlice.actions;

export default testCardSlice.reducer;
