import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import paths from '../paths';
import Loader from '../../components/atoms/loader/Loader';
const CampaignLayout = lazy(() => import('../../components/layout/campaign/CampaignLayout'));
const Campaigns = lazy(() => import('../../components/pages/campaign/campaigns/Campaigns'));
const Categories = lazy(() => import('../../components/pages/campaign/categories/Categories'));

const createLazyComponent = (Component) => (
  <Suspense fallback={<Loader spinner />}>
    <Component />
  </Suspense>
);
export const campaignRoutes = (abilities) => {
  const createRoute = (path, Component) => ({
    path,
    element: createLazyComponent(Component),
  });

  return {
    path: paths.getCampaignLayout,
    element: createLazyComponent(CampaignLayout),
    children: [
      {
        index: true,
        element: (
          <Navigate
            replace={true}
            to={paths.campaigns}
          />
        ),
      },
      (abilities?.campaign?.read ?? false) && createRoute(paths.campaigns, Campaigns),
      (abilities?.category?.read ?? false) && createRoute(paths.categories, Categories),
    ],
  };
};
