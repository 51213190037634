import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeActionModal } from '../../../redux/slices/confirmationModalSlice';
import Button from '../../atoms/button/Button';

const ConfirmationModal = () => {
  const dispatch = useDispatch();

  const { heading, description, action, closeButtonText, actionButtonText } = useSelector((state) => state.actionModal);
  return (
    <div>
      <dialog
        id='deleteModal'
        className='modal'>
        <form
          method='dialog'
          className='modal-box delete-popup rounded-lg px-5 py-4'>
          <h3 className='font-normal text-[15px] textcolor'>{heading}</h3>
          <p className='py-2 textcolor text-small'>{description ?? 'Only an admin will be able to restore.'}</p>
          <div className='modal-action flex justify-end mt-4 '>
            <Button
              title={closeButtonText}
              isOutlined={true}
              type='submit'
              onClickHandler={() => dispatch(closeActionModal())}
              color='btn-primary'
              className='mr-4'
            />
            <Button
              title={actionButtonText}
              type='submit'
              onClickHandler={async () => {
                await action();
                dispatch(closeActionModal());
              }}
              color='btn-primary'
              className='mb-4'
            />
          </div>
        </form>
        <form
          method='dialog'
          className='modal-backdrop'>
          <button onClick={() => dispatch(closeActionModal())}>close</button>
        </form>
      </dialog>
    </div>
  );
};

export default ConfirmationModal;
