import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  banks: [],
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getBanks = createAsyncThunk('banks/getBanks', async (params) => {
  const response = await handleAPI('/platform/banks', 'get', params);
  return response;
});

const bankSlice = createSlice({
  name: 'banks',
  initialState,
  reducers: {
    editBank: (state, action) => {
      const banks = [...state.banks];
      const updatedRecord = action.payload;
      const index = banks.findIndex((bank) => bank.id === updatedRecord.id);
      if (index !== -1) {
        banks[index] = updatedRecord;
      }
      state.banks = banks;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBanks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBanks.fulfilled, (state, action) => {
      state.isLoading = false;
      state.banks = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getBanks.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});
export const { editBank } = bankSlice.actions;

export default bankSlice.reducer;
