import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  products: [],
  product: {},
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getProducts = createAsyncThunk('products/getProducts', async (params) => {
  const response = await handleAPI('/platform/products', 'get', params);
  return response;
});

export const getProduct = createAsyncThunk('products/getProduct', async ({ id, params }) => {
  const response = await handleAPI(`/platform/products/${id}`, 'get', params);
  return response;
});

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    deleteProduct: (state, action) => {
      state.products = state.products.filter((product) => product.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getProducts.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(getProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.product = action.payload;
    });
  },
});
export const { deleteProduct } = productSlice.actions;

export default productSlice.reducer;
