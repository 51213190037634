import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import paths from '../paths';
import Loader from '../../components/atoms/loader/Loader';

const ProductLayout = lazy(() => import('../../components/layout/product/ProductLayout'));
const Product = lazy(() => import('../../components/pages/product/products'));
const AddProduct = lazy(() => import('../../components/pages/product/products/AddProduct'));
const Variants = lazy(() => import('../../components/pages/product/products/variant/Variants'));
const AddVariant = lazy(() => import('../../components/pages/product/products/variant/AddVariant'));
const Verticals = lazy(() => import('../../components/pages/product/verticals/Verticals'));
const OptionTypes = lazy(() => import('../../components/pages/product/optionType/OptionTypes'));

const createLazyComponent = (Component) => (
  <Suspense fallback={<Loader spinner />}>
    <Component />
  </Suspense>
);

export const productRoutes = (abilities) => {
  const createRoute = (path, Component) => ({
    path,
    element: createLazyComponent(Component),
  });

  return {
    path: paths.getProductLayout,
    element: createLazyComponent(ProductLayout),
    children: [
      {
        index: true,
        element: (
          <Navigate
            replace={true}
            to={paths.products}
          />
        ),
      },
      (abilities?.product?.read ?? false) && createRoute(paths.products, Product),
      (abilities?.product?.write ?? false) && createRoute(paths.newProduct, AddProduct),
      (abilities?.product?.write ?? false) && createRoute(paths.editProduct(), AddProduct),
      (abilities?.product?.read ?? false) && createRoute(paths.variants(), Variants),
      (abilities?.product?.write ?? false) && createRoute(paths.newVariant(), AddVariant),
      (abilities?.product?.write ?? false) && createRoute(paths.editVariant(), AddVariant),
      (abilities?.vertical?.read ?? false) && createRoute(paths.verticals, Verticals),
      (abilities?.option_types?.read ?? false) && createRoute(paths.optionTypes, OptionTypes),
    ],
  };
};
