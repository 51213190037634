import React from 'react';
import { Outlet } from 'react-router-dom';
import paths from '../../../constants/paths';
import SidebarItem from '../../atoms/sidebarItem/SidebarItem';

const CustomerLayout = () => {
  return (
    <div className='grid grid-cols-12 xl:gap-[30px] lg:gap-[24px] lg:mt-[24px] xl:mt-[30px]'>
      <div className='col-start-1 col-end-3 background py-2 px-[16px] h-fit rounded-lg text-hover sticky top-[30px]'>
        <ul>
          <li>
              <SidebarItem
                name='Customers'
                path={paths.customers}
              />
              <SidebarItem
                name='Order Entry'
                path={paths.orderEntry}
              />
          </li>
        </ul>
      </div>
      <div className='col-start-3 col-end-13'>
        <Outlet />
      </div>
    </div>
  );
};

export default CustomerLayout;
