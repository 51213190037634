import React from 'react';

const Tooltip = ({ infoData, className }) => {
  infoData.tooltip = infoData.tooltip || <span className='material-symbols-outlined text-large text-primary cursor-help'>info</span>;
  return (
    <div className={`tooltip ${className} cursor-help`}>
      <div className={`tooltip-body ${infoData.position ? infoData.position : 'tooltip-bottom'}`}>
        {infoData?.title && <p className='text-[14px] font-bold border-border p-2 border-b'>{infoData?.title}</p>}
        <div className={`${infoData.content ? 'p-2' : ''}`}>{infoData?.content}</div>
        {infoData.warning && (
          <div className='flex items-center gap-1 p-2'>
            <p className='material-symbols-outlined text-danger filled-icon'>warning</p>
            <p className='text-danger'>{infoData?.warning}</p>
          </div>
        )}
      </div>
      {infoData.tooltip}
    </div>
  );
};

export default Tooltip;
