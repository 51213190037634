import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  heading: '',
  description: '',
  closeButtonText: 'Cancel',
  actionButtonText: 'Delete',
  action: () => null,
};

const confirmationModalSlice = createSlice({
  name: 'confirmationModal',
  initialState,
  reducers: {
    openActionModal: (state, action) => {
      window.deleteModal.showModal();
      state.heading = action.payload.heading;
      state.description = action.payload.description;
      state.closeButtonText = action.payload.closeButtonText ?? 'Cancel';
      state.actionButtonText = action.payload.actionButtonText ?? 'Delete';
      state.action = action.payload.action;
    },
    closeActionModal: (state) => {
      state.heading = '';
      state.action = null;
    },
  },
});
export const { openActionModal, closeActionModal } = confirmationModalSlice.actions;

export default confirmationModalSlice.reducer;
