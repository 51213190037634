import React from 'react';
import TableCell from '../tableCell/TableCell';

const Loader = ({ rowCount, columnCount, spinner }) => {
  const rows = Array.from({ length: rowCount }).map((_, rowIndex) => (
    <tr key={`row-${rowIndex}`}>
      {Array.from({ length: columnCount }).map((_, colIndex) => (
        <TableCell key={`col-${rowIndex}-${colIndex}`}>
          {colIndex === columnCount - 1 ? (
            <div className='flex gap-2'>
              {Array.from({ length: 3 }).map((_, index) => (
                <div
                  key={index}
                  className='skeleton w-8 h-8 rounded-full shrink-0'></div>
              ))}
            </div>
          ) : (
            <div className='skeleton w-32 h-4 mb-2'></div>
          )}
        </TableCell>
      ))}
    </tr>
  ));

  return (
    <>
      {spinner ? (
        <div className='flex flex-col h-[calc(100vh-162px)]'>
          <div className='flex-grow flex justify-center items-center'>
            <span className='loading loading-spinner loading-lg text-primary'></span>
          </div>
        </div>
      ) : (
        <div className='overflow-x-auto background rounded-t-lg table-shadow mt-4'>
          <table className='w-full table-color table'>
            <tbody>{rows}</tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Loader;
