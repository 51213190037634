const productPaths = {
  products: '/product/products',
  newProduct: '/product/products/new',
  editProduct: (id = ':id') => `/product/products/${id}/edit`,
  variants: (id = ':id') => `/product/products/${id}/variants`,
  newVariant: (id = ':id') => `/product/products/${id}/variants/new`,
  editVariant: (id = ':id', vid = ':vid') => `/product/products/${id}/variants/${vid}/edit`,
  verticals: '/product/verticals',
  optionTypes: '/product/option-types',
};

export default productPaths;
