import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  logs: [],
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getLogs = createAsyncThunk('logs/getLogs', async (types) => {
  const response = await handleAPI(`/platform/activity/${types.id}`, 'get', types.params);
  return response;
});

const logsSlice = createSlice({
  name: 'logs',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getLogs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.logs = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getLogs.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default logsSlice.reducer;
