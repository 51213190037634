import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  notifications: [],
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getNotifications = createAsyncThunk('notifications/getNotifications', async (params) => {
  const response = await handleAPI('platform/notifications', 'get', params);
  return response;
});

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    deleteNotification: (state, action) => {
      state.notifications = state.notifications.filter((notification) => notification.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notifications = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { deleteNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
