import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  pixels: [],
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getPixels = createAsyncThunk('pixels/getPixels', async ({ id, params }) => {
  const response = await handleAPI(`platform/affiliates/${id}/pixels`, 'get', params);
  return response;
});

const pixelSlice = createSlice({
  name: 'pixel',
  initialState,
  reducers: {
    deletePixel: (state, action) => {
      state.pixels = state.products.filter((pixel) => pixel.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPixels.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPixels.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pixels = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getPixels.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});
export const { deletePixel } = pixelSlice.actions;

export default pixelSlice.reducer;
