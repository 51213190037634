import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  transactions: [],
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getTransactions = createAsyncThunk('orders/getTransactions', async (params) => {
  const response = await handleAPI('/platform/transactions', 'get', params);
  return response;
});

const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTransactions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactions = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getTransactions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default transactionSlice.reducer;
