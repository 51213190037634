import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  gatewayProfiles: [],
  gatewayProfile: {},
  pools: [],
  gateways: [],
  binRoutings: [],
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getGatewayProfiles = createAsyncThunk('gatewayProfile/getGatewayProfiles', async (params) => {
  const response = await handleAPI('/platform/gateway_profiles', 'get', params);
  return response;
});

export const getGatewayProfile = createAsyncThunk('gatewayProfile/getGatewayProfile', async ({ id, params }) => {
  const response = await handleAPI(`/platform/gateway_profiles/${id}`, 'get', params);
  return response;
});

export const getPools = createAsyncThunk('gatewayProfile/getPools', async ({ id, params }) => {
  const response = await handleAPI(`/platform/gateway_profiles/${id}/pools`, 'get', params);
  return response;
});

export const getGateways = createAsyncThunk('gatewayProfile/getGateways', async ({ id, params }) => {
  const response = await handleAPI(`/platform/gateway_profiles/${id}/gateways`, 'get', params);
  return response;
});

export const getBinRoutings = createAsyncThunk('gatewayProfile/getBinRoutings', async ({ id, params }) => {
  const response = await handleAPI(`/platform/gateway_profiles/${id}/bin_routings`, 'get', params);
  return response;
});

const gatewayProfileSlice = createSlice({
  name: 'gatewayProfile',
  initialState,
  reducers: {
    deleteGatewayProfile: (state, action) => {
      state.gatewayProfiles = state.gatewayProfiles.filter((gatewayProfile) => gatewayProfile.id !== action.payload);
    },
    editGatewayProfile: (state, action) => {
      const gatewayProfiles = [...state.gatewayProfiles];
      const updatedRecord = action.payload;
      const index = gatewayProfiles.findIndex((gatewayProfile) => gatewayProfile.id === updatedRecord.id);
      if (index !== -1) {
        gatewayProfiles[index] = updatedRecord;
      }
      state.gatewayProfiles = gatewayProfiles;
      state.isLoading = false;
    },
    editProfilePool: (state, action) => {
      const pools = [...state.pools];
      const updatedRecord = action.payload;
      const index = pools.findIndex((pool) => pool.id === updatedRecord.id);
      if (index !== -1) {
        pools[index] = updatedRecord;
      }
      state.pools = pools;
      state.isLoading = false;
    },
    editProfileGateway: (state, action) => {
      const gateways = [...state.gateways];
      const updatedRecord = action.payload;
      const index = gateways.findIndex((gateway) => gateway.id === updatedRecord.id);
      if (index !== -1) {
        gateways[index] = updatedRecord;
      }
      state.gateways = gateways;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGatewayProfiles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getGatewayProfiles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.gatewayProfiles = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
    builder.addCase(getGatewayProfiles.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getGatewayProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.gatewayProfile = action.payload;
    });
    builder.addCase(getPools.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPools.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pools = action.payload;
    });
    builder.addCase(getGateways.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getGateways.fulfilled, (state, action) => {
      state.isLoading = false;
      state.gateways = action.payload;
    });
    builder.addCase(getBinRoutings.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBinRoutings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.binRoutings = action.payload;
    });
  },
});

export const { deleteGatewayProfile, editGatewayProfile, editProfilePool, editProfileGateway } = gatewayProfileSlice.actions;

export default gatewayProfileSlice.reducer;
