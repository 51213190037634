import React from 'react';

const HomeSkeleton = () => {
  return (
    <div className='screenbackground h-screen'>
      <div className='grid grid-cols-12'>
        <div className='col-start-1 col-end-2'>
          <div className='sidemenu h-screen overflow-auto'>
            <div className='sidemenu py-7 sticky top-0 xl:w-auto lg:w-[5rem] 2xl:w-[auto]'>
              <div className='skeleton w-full h-8 mb-2'></div>
            </div>
            {[...Array(6)].map((_, index) => (
              <div key={index}>
                <div className='skeleton w-full h-20 mb-2'></div>
              </div>
            ))}
          </div>
        </div>
        <div className='col-start-2 col-end-13 h-screen flex flex-col'>
          <div className='navbar background px-4 flex justify-end header-shadow z-50 gap-2'>
            <div className='flex-none'>
              <div className='skeleton w-8 h-8 rounded-full shrink-0 mr-4'></div>
              <div className='skeleton w-8 h-8 rounded-full shrink-0 mr-4'></div>
              <div>
                <div className='skeleton w-20 h-2 mb-1'></div>
                <div className='skeleton w-10 h-2'></div>
              </div>
              <div className='skeleton w-8 h-8 rounded-full shrink-0 ml-2'></div>
            </div>
          </div>
          <div className='px-4 py-2 header-shadow z-20 background'>
            <div className='breadcrumbs xl:text-small lg:text-[12px]'>
              <ul>
                <li>
                  <div className='skeleton w-20 h-4'></div>
                </li>
                <li>
                  <div className='skeleton w-20 h-4'></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSkeleton;
