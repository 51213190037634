import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Toastr from './components/atoms/toasts/Toastr';
import useAxiosPrivate from './hooks/auth/useAxiosPrivate';
import ConfirmationModal from './components/molecule/modal/ConfirmationModal';
import { router } from './constants/routes';

function App() {
  useAxiosPrivate();
  const { theme } = useSelector((state) => state.theme);
  const { role } = useSelector((state) => state.auth);
  const { abilities } = useSelector((state) => state.auth);

  return (
    <div
      id='body'
      data-theme={theme}>
      <RouterProvider
        router={router(role, abilities)}
        fallbackElement={<>loading...</>}
      />
      <Toastr />
      <ConfirmationModal />
    </div>
  );
}

export default App;
